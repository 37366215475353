import { registerApplication, start, navigateToUrl } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import { spinner } from './spinner';
import { BdbWebTracer } from '@npm-bbta/sdk-mfe-utils-opentelemetry';
import { InstrumentationEnum } from '@npm-bbta/sdk-mfe-utils-opentelemetry/enums/InstrumentationEnum';
import { utilsHotjarCeropay } from '../utils/utilsHotjarCeropay'
const BrowserDetector = require('browser-dtector');

const allowBrowsers = ['Google Chrome', 'Safari'];
const data = {
  props: {},
  loaders: {
    spinner: spinner,
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

start();
let trace = null;
window.addEventListener ("bnpTraces",(event:any)=>{
  trace = new BdbWebTracer({
    appName: `Aliados-Ecommerce-Ceropay-Front`,
    team: 'BNPL',
    collectorHost: event.detail.collectorHost,
    instruments: [InstrumentationEnum.XML_HTTP_REQUEST],
    processorType: 'Simple',
    spanTypes: ['OTLP'],
    propagateTraceHeaderCorsUrls: [],
    propagateHeaders: ['x-auth-token', 'x-rquid'],
    propagateStorage: true,
    metrics: {
      enabled: true,
      sandboxConfig: {
        encryptKey: event.detail.encryptKey,
        decryptKey: event.detail.decryptKey,
        properties: {
          Parameters: {
            Time: 5,
            Body: 3,
            Iv: 2,
            Pk1: 1,
            Pk2: 4,
            Total: 6,
          },
          Expiration: {
            Time: 5,
            Unit: 'minutes',
          },
        },
      },
      endpoint: event.detail.metrictsEndpoint,
    }
  })
  trace.init();
});

interface UrlEvent {
  url: string;
}

export type UrlEventType = CustomEventInit<UrlEvent>;
window.addEventListener('changeURL', (e: UrlEventType) => {
  trace.registerCustomTrace(
      'Widget-Navigation-bdb-onboarding-bnpl',
      {
        framework: 'Angular',
        'event.navigation': e.detail.url,
      },
      true
  );
  navigateToUrl(e.detail.url);
});

window.addEventListener('HotjarCeropay', (event) => {
  const browser = new BrowserDetector(window.navigator.userAgent).getBrowserInfo();
  if (allowBrowsers.includes(browser.name)) {
    utilsHotjarCeropay(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
  }
});

export function publicApiFunction() {
  return 0;
}
