
export function utilsHotjarCeropay (h: any, o, t, j) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments); // NOSONAR
      };
    h._hjSettings = {
      hjid: 2905265,
      hjsv: 6,
    };
    const a = o.getElementsByTagName('head')[0];
    const r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
};