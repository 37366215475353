export const spinner = `<style>
.container {
  position: fixed;
  z-index: 20000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease 0s;
}

.spinner-component {
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  width: 40px;
}

.spinner-parent {
  top: 0;
  z-index: 22000;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: rgba(196, 212, 227, 0.8);
  opacity: 1;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 1s ease 0s, transform 0.25s ease 0s;
}

@-webkit-keyframes rotating
  /* Safari and Chrome */

{
  from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.hide {
  display: none;
}
</style>
<div class="container spinner-parent">
<div id='spinner'>
<svg class="spinner-component rotating" width="45px" height="47px" viewBox="0 0 45 47" xmlns="http://www.w3.org/2000/svg">
    <title>Loader</title>
    <g id="MVP-(Widget)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Loader">
            <circle id="Oval" fill="#3C7FB9" cx="36.9140625" cy="10.1953125" r="5.2734375"></circle>
            <circle id="Oval-Copy" fill="#3C7FB9" cx="23.203125" cy="4.921875" r="4.921875"></circle>
            <circle id="Oval-Copy-7" fill="#548FC2" cx="9.4921875" cy="10.1953125" r="4.5703125"></circle>
            <circle id="Oval-Copy-2" fill="#82ADD2" cx="4.21875" cy="23.90625" r="4.21875"></circle>
            <circle id="Oval-Copy-3" fill="#82ADD2" cx="9.84375" cy="37.265625" r="4.21875"></circle>
            <circle id="Oval-Copy-4" fill="#B0CAE3" cx="23.203125" cy="42.890625" r="3.515625"></circle>
            <circle id="Oval-Copy-6" fill="#B0CAE3" cx="36.9140625" cy="36.9140625" r="3.1640625"></circle>
            <circle id="Oval-Copy-5" fill="#C6DAEB" cx="42.1875" cy="23.90625" r="2.8125"></circle>
        </g>
    </g>
</svg>
</div>
</div>`;
